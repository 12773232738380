<template>
  <!--Red Sosicla, este es un for imput text que se genera del objeto redesSociales que se encuantra en el store y a la vez, guarda los datos en el Objeto redes sociales. data-->
  <v-window-item :value="4">
    <v-card-text>
      <v-row>
        <v-col
          v-for="red in contacto.redesSociales.redes"
          :key="red.name"
          cols="12"
          sm="6"
        >
          <v-text-field
            :label="red.name"
            v-model="contacto.redesSociales.data[red.name]"
            :prepend-inner-icon="red.icon"
            :placeholder="red.placeholder"
            :type="red.type"
            filled
            :rules="[red.rule]"
            rounded
            dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-window-item>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["contacto", "redesSociales"]),
  },
};
</script>
