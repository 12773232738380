<template>
  <v-app>
    <!-- <v-navigation-drawer app>
    </v-navigation-drawer>-->
    <v-card flat v-if="$route.name != 'me'">
      <v-toolbar color="primary" dark extended flat>
        <v-img
          max-width="120px"
          src="./img/logoWhite.svg"
          alt="Logo Ver Contacto"
        />
        V{{ this.app.version }}
      </v-toolbar>

      <v-container>
        <v-card elevation="1" style="margin-top: -55px">
          <v-toolbar flat>
            <v-toolbar-title class="black--text">
              {{ this.$route.name }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <a href="http://ypw.com.do/" target="_blank"
              ><v-btn icon>
                <v-icon>mdi-apps</v-icon>
              </v-btn></a
            >
          </v-toolbar>

          <v-divider></v-divider>

          <v-card-text v-if="this.$route.name == 'Home'">
            <v-img
              lazy-src="./img/slidePreviewAsync.jpg"
              style="background-color: white"
              src="./img/slidePreview.svg"
            ></v-img>
          </v-card-text>
        </v-card>
      </v-container>
    </v-card>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer dark padless v-if="$route.name != 'me'">
      <v-card class="flex secondary" flat tile>
        <v-card-text class="py-2 white--text text-center">
          <strong> © {{ new Date().getFullYear() }}</strong> —
          <a
            class="white--text"
            href="https://ypw.com.do"
            target="_blank"
            rel="YPW S.R.L Logo"
          >
            YPW S.R.L</a
          >
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import app from "../package.json";
export default {
  data: () => ({
    app: app,
    drawer: null,
    links: ["Dashboard", "Messages", "Profile", "Updates"],
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<style>
a {
  text-decoration: none;
}
</style>
