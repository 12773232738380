<template>
  <v-window-item :value="2">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-textarea
            solo
            v-model="contacto.info"
            prepend-inner-icon="mdi-information-outline"
            name="input-7-4"
            label="Servicios o Detalles"
            counter
            :rules="[rules.info]"
            maxlength="200"
            placeholder="Servicios o Detalles"
          ></v-textarea>
        </v-col>

        <v-col cols="5">
          <v-select
            rounded
            v-model="protocol"
            @change="webAddProtocol"
            :items="protocols"
            label="Protocol"
            solo
          ></v-select>
        </v-col>

        <v-col cols="7">
          <v-text-field
            label="Sitio Web"
            v-model="webReponse"
            prepend-inner-icon="mdi-web"
            placeholder="web"
            filled
            rounded
            dense
          ></v-text-field>
        </v-col> </v-row
    ></v-card-text>
  </v-window-item>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      protocols: ["http://", "https://"],
      protocol: "http://",
      webReponse: "",
    };
  },
  methods: {
    webAddProtocol() {
      //Agregar Link con el protocolo si el Web reponse es diferente a Null

      let webReponse = this.webReponse
        .replace("http://", "")
        .replace("https://", "");

      if (webReponse) {
        this.contacto.web = this.protocol + webReponse;
      } else {
        this.contacto.web = null;
      }

      //Eliminar http, https si Web Reponse lo contiene
      this.webReponse = this.webReponse
        .replace("http://", "")
        .replace("https://", "");
    },
  },
  watch: {
    webReponse(web) {
      //Inicia de acuerdo al imput del texto
      this.webAddProtocol(web);
    },
  },
  computed: {
    ...mapState(["contacto", "rules"]),
  },
};
</script>
