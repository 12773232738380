<template>
  <div>
    <VC-plantilla
      v-if="this.shareContacto"
      :contacto="this.shareContacto"
    ></VC-plantilla>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VCPlantilla from "@/components/VCPlantilla";
export default {
  data() {
    return {};
  },
  computed: { ...mapState(["shareContacto"]) },
  methods: {
    ...mapActions(["getShare"]),
  },
  components: { VCPlantilla },
  created() {
    this.getShare(this.$route.params.share);
  },
};
</script>
