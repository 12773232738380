import store from "@/store/";
const dataTitle = ["Personal", "Teléfonos", "Información", "Diseño", "Redes"];

const currentTitle = () => {
  switch (store.state.step) {
    case 0:
      return dataTitle[0];
    case 1:
      return dataTitle[1];
    case 2:
      return dataTitle[2];

    case 3:
      return dataTitle[3];
    case 4:
      return dataTitle[4];

    default:
      return dataTitle[0];
  }
};

export { currentTitle, dataTitle };
