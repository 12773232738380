import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import me from "@/views/me";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/me/:share",
    name: "me",
    params: {
      share: {
        type: String,
        default: null,
      },
    },
    component: me,
  },
  {
    path: "*",
    name: "Error 404",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
