import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router";

import redesSociales from "@/data/redes";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    shareContacto: {},
    api: {
      url: "https://api.vercontacto.com/",
    },
    system: {
      loading: false,
      error: false,
      message: null,
      push: false,
    },
    colorPike: {
      active: false,
    },
    step: 0,
    contacto: {
      direccion: null,
      nombre: null,
      email: null,
      numero: null,
      numero2: null,
      numero3: null,
      numero4: null,
      info: null,
      color: {
        backGround: "#f0f0f0f0",
        title: "#000",
        text: "#000",
        icon: "#000",
        button: "#fff",
      },
      redesSociales: {
        /*Se auto genera un for de impul del lado del html y se los datos de guardan en data, de redes, se pasan a Data*/
        data: {},
        redes: redesSociales,
      },
      web: null,
      logo: "",
    },
    rules: {
      nombre: (v) =>
        /^[a-zA-Z .-_&0-9+()%üéáíóúñÑ¿¡ÁÉÍÓÚÚ]{2,40}$/.test(v) ||
        "Error de Nombre",
      numero: (v) =>
        /^[+]?(\d{1,4})?\s?-?[.]?[(]?\d{3}[)]?\s?-?[.]?\d{3}\s?-?[.]?\d{4}$/.test(
          v
        ) || "Numero Incorrecto",
      email: (v) =>
        /^[^@]+@[^@]+\.[a-zA-Z]{2,150}$/.test(v) || !v || "Email Incorrecto",
      direccion: (v) => /^(.){2,100}$/.test(v) || !v || "Direccion Incorrecta",
      info: (v) =>
        /^.{5,200}$/.test(v) || !v || "Servicios o Detalles Incorrecto",
    },
  },
  mutations: {
    setSystem(state, system) {
      state.system = system;
    },
    setContacto(state, contacto) {
      //Guardar Nuevamente las redes en caso de mutacion
      contacto.redesSociales.redes = redesSociales;
      state.contacto = contacto;
    },
  },
  actions: {
    validarContacto(context) {
      //Data

      const data = {};

      //data.logo = context.state.contacto.logo;
      data.direccion = context.state.contacto.direccion;
      data.nombre = context.state.contacto.nombre;
      data.email = context.state.contacto.email;
      data.numero = context.state.contacto.numero;
      data.numero2 = context.state.contacto.numero2;
      data.numero3 = context.state.contacto.numero3;
      //data.numero4 = context.state.contacto.numero4;
      data.info = context.state.contacto.info;
      //data.color = JSON.stringify(context.state.contacto.color);
      //data.redesSociales = JSON.stringify(context.state.contacto.redesSociales);
      data.web = context.state.contacto.web;

      /*
       * ---------------------------------------
       *  Methodo de Valqaaqidacion de Datos
       *  Antes de Enviar, Validar Datos
       * ---------------------------------------
       */

      if (!/^[a-zA-Z .-_&0-9+()%üéáíóúñÑ¿¡ÁÉÍÓÚÚ]{2,40}$/.test(data.nombre)) {
        context.state.system.push = false;
      } else if (
        !/^[+]?(\d{1,4})?\s?-?[.]?[(]?\d{3}[)]?\s?-?[.]?\d{3}\s?-?[.]?\d{4}$/.test(
          data.numero
        )
      ) {
        context.state.system.push = false;
      } else {
        context.state.system.push = true;
      }

      //push
    },
    //Generar o Agrgar Contacto
    generarContacto: async (context) => {
      try {
        const data = {};

        context.state.system.loading = true;
        let api = context.state.api.url;

        /* Api 
                http://localhost/api-vercontactos/api/v3/contacto/generar
                {
                  "logo":"https:22",
                  "direccion":"Sanchez Ramirez, Prolongacion Luperon, #22",
                  "nombre":"Yolfry Bautista Reynoso",
                  "email":"yolfri1997@gmail.com",
                  "numero":"8295571997",
                  "numero2”:”8295571997",
                  "numero3”:”8295571997",
                  "numero4”:”8295571997",
                  "info":"Hola Como estas?",
                  "color":"rgba(00000,0000,0000,0000)",
                  "redesSociales":[{"whatsapp":"8295571997"}],
                  "web":"https://google.com"
                }
          */

        //Datos para enviar al Servidor Api
        data.logo = context.state.contacto.logo;
        data.direccion = context.state.contacto.direccion;
        data.nombre = context.state.contacto.nombre;
        data.email = context.state.contacto.email;
        data.numero = context.state.contacto.numero;
        data.numero2 = context.state.contacto.numero2;
        data.numero3 = context.state.contacto.numero3;
        data.numero4 = context.state.contacto.numero4;
        data.info = context.state.contacto.info;
        data.color = JSON.stringify(context.state.contacto.color);
        data.redesSociales = JSON.stringify(
          context.state.contacto.redesSociales
        );
        data.web = context.state.contacto.web;

        const res = await axios.post(api + "api/v3/contacto/generar", data);

        if (!res.data.error) {
          //console.log(res.data);
          localStorage.contacto = null;
          router.push({
            name: "me",
            params: {
              share: res.data.res,
            },
          });
          context.state.system.loading = false;
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        context.state.system.loading = false;
        context.state.system.error = true;
        context.state.system.message = error.message;
      }
    },
    getShare: async (context, share) => {
      try {
        const data = {};
        context.state.system.loading = true;
        let api = context.state.api.url;

        /* Api 
           http://localhost/api-vercontactos/api/v3/contacto/share
           {
             "share":"6204435bde2e2"
           }
        */

        //Datos para enviar al Servidor Api
        data.share = share;

        const res = await axios.post(api + "api/v3/contacto/share", data);

        if (!res.data.error) {
          const shareData = await res.data.res;

          shareData.color = await JSON.parse(shareData.color);
          shareData.redesSociales = await JSON.parse(shareData.redesSociales);

          context.state.shareContacto = shareData;

          context.state.system.loading = false;
        } else {
          throw new Error(res.data.message);
        }
      } catch (error) {
        context.state.system.loading = false;
        context.state.system.error = true;
        context.state.system.message = error.message;
      }

      //li
    },
  },

  modules: {},
});
