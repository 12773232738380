<template>
  <div>
    <v-card
      v-if="this.contacto.color"
      :style="`background-color: ${contacto.color.backGround}`"
      rounded
      elevation="0"
    >
      <v-container>
        <v-row>
          <v-scroll-y-transition>
            <!--Logo-->
            <v-col
              @click.right="$store.state.step = 3"
              v-if="this.contacto.logo"
              cols="12"
              class="text-center"
            >
              <v-avatar size="150">
                <img
                  :src="
                    $route.name == 'Home'
                      ? this.contacto.logo
                      : $store.state.api.url + 'images/' + this.contacto.logo
                  "
                  alt="Logo"
                />
              </v-avatar>
            </v-col>
          </v-scroll-y-transition>

          <v-scroll-y-transition>
            <!--Logo-->
            <v-col cols="12" class="text-center">
              {{ this.resultP }}
              <v-btn
                @click="saveContact()"
                color="green"
                class="ma-2 white--text"
              >
                Guardar Contacto
                <v-icon right dark> mdi-card-account-phone </v-icon>
              </v-btn>
            </v-col>
          </v-scroll-y-transition>

          <!--Bloque Nombre-->

          <v-scroll-y-transition>
            <v-col
              @click.right="$store.state.step = 0"
              v-if="this.contacto.nombre"
              cols="12"
              class="text-center"
            >
              <span :style="`color: ${contacto.color.title}`" class="title">{{
                this.contacto.nombre
              }}</span>
            </v-col>
          </v-scroll-y-transition>

          <v-scroll-y-transition>
            <!--Bloque direccion-->
            <v-col
              @click.right="$store.state.step = 0"
              cols="12"
              v-if="this.contacto.direccion"
              class="text-center"
            >
              <p :style="`color: ${contacto.color.text}`">
                <v-icon :style="`color: ${contacto.color.icon}`"
                  >mdi-map-marker</v-icon
                >
                {{ this.contacto.direccion }}
              </p>
            </v-col>
          </v-scroll-y-transition>

          <v-scroll-y-transition
            ><v-col
              class="pa-2 d-flex justify-center text-justify px-15 body-1"
              v-if="this.contacto.info"
              cols="12"
              @click.right="$store.state.step = 2"
            >
              <p mandatory :style="`color: ${contacto.color.text}`">
                {{ this.contacto.info }}
              </p>
            </v-col></v-scroll-y-transition
          >

          <v-scroll-y-transition>
            <v-col
              @click.right="$store.state.step = 1"
              cols="12"
              v-show="contacto.numero"
              class="text-center"
            >
              <a target="_blank" :href="`tel:${contacto.numero}`"
                ><v-btn
                  outlined
                  :style="`color: ${contacto.color.text}`"
                  rounded
                >
                  <v-icon :style="`color: ${contacto.color.icon}`">
                    mdi-phone</v-icon
                  >
                  Llamar
                </v-btn></a
              >
            </v-col></v-scroll-y-transition
          >

          <v-scroll-y-transition>
            <v-col
              @click.right="$store.state.step = 4"
              cols="12"
              v-if="contacto.redesSociales.data.Whatsapp"
              class="text-center"
            >
              <a
                target="_blank"
                :href="`${contacto.redesSociales.redes[1].link}${contacto.redesSociales.data['Whatsapp']}`"
              >
                <v-btn
                  :style="`color: ${contacto.color.text}`"
                  class="mx-3"
                  rounded
                  outlined
                  elevation="0"
                >
                  <v-icon :style="`color: ${contacto.color.icon}`">
                    mdi-whatsapp</v-icon
                  >
                  Whatsapp
                </v-btn>
              </a>
            </v-col>
          </v-scroll-y-transition>

          <v-scroll-y-transition>
            <v-col
              @click.right="$store.state.step = 0"
              cols="12"
              v-show="contacto.email"
              class="text-center"
            >
              <a target="_blank" :href="`mailto:${contacto.email}`">
                <v-btn
                  :style="`color: ${contacto.color.text}`"
                  class="mx-3"
                  rounded
                  outlined
                  elevation="0"
                >
                  <v-icon :style="`color: ${contacto.color.icon}`">
                    mdi-email</v-icon
                  >
                  Correo
                </v-btn>
              </a>
            </v-col>
          </v-scroll-y-transition>

          <v-scroll-y-transition
            ><v-col
              @click.right="$store.state.step = 1"
              v-if="contacto.numero2"
              cols="12"
              class="text-center"
            >
              <a target="_blank" :href="`tel:${contacto.numero2}`"
                ><v-btn
                  elevation="0"
                  :style="`color: ${contacto.color.text};background-color: ${contacto.color.button};`"
                >
                  <v-icon :style="`color: ${contacto.color.icon}`">
                    mdi-phone</v-icon
                  >
                  Trabajo
                </v-btn></a
              >
            </v-col></v-scroll-y-transition
          >

          <v-scroll-y-transition>
            <v-col
              @click.right="$store.state.step = 1"
              v-if="contacto.numero3"
              cols="12"
              class="text-center"
            >
              <a target="_blank" :href="`tel:${contacto.numero3}`"
                ><v-btn
                  elevation="0"
                  :style="`color: ${contacto.color.text};background-color: ${contacto.color.button};`"
                >
                  <v-icon :style="`color: ${contacto.color.icon}`">
                    mdi-phone</v-icon
                  >
                  Casa
                </v-btn></a
              >
            </v-col>
          </v-scroll-y-transition>

          <v-scroll-y-transition>
            <v-col
              @click.right="$store.state.step = 2"
              v-if="
                this.contacto.web != 'http://' &&
                this.contacto.web != 'https://'
                  ? this.contacto.web
                  : null
              "
              cols="12"
              class="text-center"
            >
              <a
                class="text-h6 transition-swing px-2 py-2"
                :style="`color: ${contacto.color.text};background-color: ${contacto.color.button};`"
                :href="`${contacto.web}`"
                target="_blank"
              >
                <v-icon :style="`color: ${contacto.color.icon}`"
                  >mdi-web</v-icon
                >
                {{
                  this.contacto.web
                    .replace("http://", "")
                    .replace("https://", "")
                }}</a
              >
            </v-col>
          </v-scroll-y-transition>

          <v-scroll-y-transition>
            <!--Redes Sociales-->
            <v-col
              v-if="contacto.redesSociales.redes"
              cols="12"
              class="text-center"
              @click.right="$store.state.step = 4"
            >
              <template v-for="redSocial in contacto.redesSociales.redes">
                <a
                  v-if="contacto.redesSociales.data[redSocial.name]"
                  :key="redSocial.name"
                  :href="`${redSocial.link}${
                    contacto.redesSociales.data[redSocial.name]
                  }`"
                  target="_blank"
                  class="px-1"
                >
                  <v-avatar size="40">
                    <img :src="redSocial.img" :alt="redSocial.name" />
                  </v-avatar>
                </a>
              </template> </v-col
          ></v-scroll-y-transition>

          <v-scroll-y-transition>
            <v-col
              v-if="this.$route.name != 'Home'"
              cols="12"
              class="text-center justify-center mx-auto"
            >
              <a
                style="background-color: white; border-radius: 130px"
                href="https://vercontacto.com"
                target="_blank"
                rel=""
                ><v-img
                  style="background-color: white; border-radius: 130px"
                  class="mx-auto"
                  max-height="80"
                  max-width="100"
                  src="/img/verContactoLogo.svg"
                ></v-img>
                <span class="secondary--text">Clic para crear gratis! </span></a
              >
            </v-col></v-scroll-y-transition
          >
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<style scoped></style>

<script>
export default {
  name: "Plantilla",
  data() {
    return {
      resultP: "",
    };
  },
  methods: {
    async saveContact() {
      var contacto = {
        nombre: this.contacto.nombre,
        numero: this.contacto.numero,
        correo: this.contacto.email,
      };

      var vCardContent = "BEGIN:VCARD\n";
      vCardContent += "VERSION:3.0\n";
      vCardContent += "N:" + contacto.nombre + "\n";
      vCardContent += "TEL;TYPE=CELL:" + contacto.numero + "\n";
      vCardContent += "EMAIL:" + contacto.correo + "\n";
      vCardContent += "END:VCARD\n";

      var encodedUri = encodeURI(
        "data:text/vcard;charset=utf-8," + vCardContent
      );
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "contacto.vcf");
      document.body.appendChild(link); // required for firefox
      link.click();

      navigator.share({
        title: "Archivo de contactos",
        text: "Descarga el archivo VCF con tus contactos",
        url: link.href,
      });
    },
  },
  props: {
    contacto: {
      type: Object,
      default: null,
    },
  },
};
</script>
