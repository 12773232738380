<template>
  <div class="home">
    <v-container>
      <v-snackbar v-model="$store.state.system.error" vertical>
        {{ $store.state.system.message }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="error"
            text
            v-bind="attrs"
            @click="$store.state.system.error = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>

      <v-row>
        <v-col cols="12" sm="8">
          <!--<v-icon class="grey--text">mdi-view-comfy</v-icon>-->
          <VC-plantilla :contacto="this.contacto"></VC-plantilla>
        </v-col>

        <v-col cols="12" sm="4">
          <VC-generador></VC-generador>
        </v-col>

        <v-col cols="12" sm="12">
          <v-tabs centered v-model="$store.state.step">
            <v-tab
              class="text-body-2"
              @click="$store.state.step = index"
              v-for="(title, index) in dataTitle"
              :key="index"
              >{{ title }}</v-tab
            >
          </v-tabs>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";

import VCGenerador from "@/components/VCGenerador";
import VCPlantilla from "@/components/VCPlantilla";
import { dataTitle } from "@/data/currentTitle";

export default {
  name: "Home",
  data() {
    return {
      dataTitle,
    };
  },
  components: { VCGenerador, VCPlantilla },
  methods: {
    // currentTitle,
  },
  computed: { ...mapState(["contacto"]) },
};
</script>
