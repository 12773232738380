<template>
  <v-window-item :value="0">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Titulo o Nombre"
            placeholder="Nombre"
            prepend-inner-icon="mdi-card-account-details"
            v-model="contacto.nombre"
            :rules="[rules.nombre]"
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            type="tel"
            label="Telefono"
            v-model="contacto.numero"
            prepend-inner-icon="mdi-phone"
            placeholder="Telefono"
            :rules="[rules.numero]"
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            label="Correo electronico"
            placeholder="Correos"
            prepend-inner-icon="mdi-email"
            :rules="[rules.email]"
            v-model="contacto.email"
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea
            solo
            name="input-7-4"
            prepend-inner-icon="mdi-map-marker"
            label="Direccion"
            counter
            :rules="[rules.direccion]"
            maxlength="100"
            v-model="contacto.direccion"
          ></v-textarea>
        </v-col>
      </v-row>

      <!--<span class="text-caption grey--text text--darken-1">
              Informacion Personal
            </span>-->
    </v-card-text>
  </v-window-item>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapState(["contacto", "rules"]),
  },
};
</script>
