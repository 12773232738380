import Vue from "vue";
import Vuetify from "vuetify";

import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const opts = {
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
  icons: {
    iconfont: "mdi",
  },
  lang: {
    current: "es",
    //locales: { en },
  },
  rtl: false,
  theme: {
    dark: false, //Soport Mode Black
    default: "light",
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: undefined,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: "#98C73A",
        secondary: "#388D9B",
        third: "#50bfc9",
        colorMenu: "#f5f5f5",
        colorSelectMenu: "#000",
        slideColor: "#388D9B",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        whiteGrey: "#e2e2e2",
      },
      dark: {
        primary: "#98C73A",
        secondary: "#424242",
        third: "#50bfc9",
        colorMenu: "#272727",
        colorSelectMenu: "#fff",
        slideColor: "#388D9B",
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
    },
  },
};

export default new Vuetify(opts);
