<template>
  <v-window-item :value="1">
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            type="tel"
            label="Telefono"
            v-model="contacto.numero"
            prepend-inner-icon="mdi-phone"
            placeholder="Telefono"
            :rules="[rules.numero]"
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Trabajo"
            v-model="contacto.numero2"
            prepend-inner-icon="mdi-phone"
            placeholder="Telefono"
            :rules="[rules.numero]"
            filled
            rounded
            type="tel"
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            label="Casa"
            v-model="contacto.numero3"
            prepend-inner-icon="mdi-phone"
            placeholder="Telefono"
            :rules="[rules.numero]"
            type="tel"
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <!--<span class="text-caption grey--text text--darken-1">
              Numero de Telefono
            </span>-->
    </v-card-text>
  </v-window-item>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["contacto", "rules"]),
  },
};
</script>
