<template>
  <v-window-item :value="3">
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="text-h6 font-weight-regular justify-space-between"
        >
          Foto o Logo
        </v-col>

        <v-col v-show="this.contacto.logo" cols="3">
          <v-avatar size="70">
            <img :src="this.contacto.logo" alt="Logo" />
          </v-avatar>
        </v-col>

        <v-col :cols="this.contacto.logo ? `9` : `12`">
          <v-file-input
            outlined
            rounded
            v-model="file"
            @change="imgBase64"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Logo"
            prepend-icon
            prepend-inner-icon="mdi-camera"
            label="Logo"
          ></v-file-input>
        </v-col>

        <v-col
          cols="12"
          class="text-h6 font-weight-regular justify-space-between"
        >
          Personalizar Colores
        </v-col>

        <v-expand-transition>
          <v-col cols="12" sm="12" v-show="colorPike.active">
            <!--hide-canvas
              hide-mode-switch
              hide-sliders
              show-swatches
              -->
            <v-color-picker
              dot-size="25"
              v-model="contacto.color[textColor]"
              mode="hexa"
              swatches-max-height="200"
            ></v-color-picker>
          </v-col>
        </v-expand-transition>

        <v-col cols="4" sm="4">
          <v-chip
            link
            color="third"
            @click="colorPikeActive('backGround')"
            class="ma-2 white--text"
          >
            <v-avatar left>
              <v-icon :style="`color:${this.contacto.color.backGround}`"
                >mdi-palette</v-icon
              >
            </v-avatar>
            Fondo
          </v-chip>
        </v-col>

        <v-col cols="4" sm="4">
          <v-chip
            link
            color="third"
            @click="colorPikeActive('title')"
            class="ma-2 white--text"
          >
            <v-avatar left>
              <v-icon :style="`color:${this.contacto.color.title}`"
                >mdi-palette</v-icon
              >
            </v-avatar>
            Titulos
          </v-chip>
        </v-col>

        <v-col cols="4" sm="4">
          <v-chip
            link
            color="third"
            @click="colorPikeActive('text')"
            class="ma-2 white--text"
          >
            <v-avatar left>
              <v-icon :style="`color:${this.contacto.color.text}`"
                >mdi-palette</v-icon
              >
            </v-avatar>
            Textos
          </v-chip>
        </v-col>

        <v-col cols="4" sm="4">
          <v-chip
            link
            color="third"
            @click="colorPikeActive('button')"
            class="ma-2 white--text"
          >
            <v-avatar left>
              <v-icon :style="`color:${this.contacto.color.button}`"
                >mdi-palette</v-icon
              >
            </v-avatar>
            Botones
          </v-chip>
        </v-col>

        <v-col cols="4" sm="4">
          <v-chip
            link
            color="third"
            @click="colorPikeActive('icon')"
            class="ma-2 white--text"
          >
            <v-avatar left>
              <v-icon :style="`color:${this.contacto.color.icon}`"
                >mdi-palette</v-icon
              >
            </v-avatar>
            Iconos
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-window-item>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return { textColor: "backGround", file: null };
  },
  methods: {
    colorPikeActive(textColor) {
      if (this.textColor != textColor) {
        this.colorPike.active = true;
      } else {
        this.colorPike.active = !this.colorPike.active;
      }

      this.textColor = textColor;
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.contacto.logo = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    imgBase64() {
      this.getBase64(this.file);
      // this.contacto.logo = this.getBase64(this.img);
      //this.contacto.logo = this.img;
    },
  },
  computed: {
    ...mapState(["contacto", "colorPike"]),
  },
};
</script>
