import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import vuetify from "@/plugins/vuetify.js"; // path to vuetify export

//Si el contacto esta guardo en el navegador Local, entonces recuperar contacto (Borrador)
try {
  let contacto = JSON.parse(localStorage.contacto);
  if (contacto) store.commit("setContacto", contacto);
} catch (error) {
  console.log(error.message);
}

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
