<template>
  <div>
    <v-card elevation="1">
      <!--<v-card-title class="text-h6 font-weight-regular justify-space-between">
        <span>{{ currentTitle }}</span>
        <v-avatar
          color="secondary"
          class="subheading white--text"
          size="24"
          v-text="this.$store.state.step"
        ></v-avatar>
      </v-card-title>-->

      <v-card-actions class="d-flex flex-row-reverse">
        <v-btn
          :loading="system.loading"
          large
          rounded
          :disabled="!system.push"
          color="primary"
          depressed
          @click="push()"
        >
          Publicar
        </v-btn>
      </v-card-actions>

      <v-divider></v-divider>

      <v-window v-model="$store.state.step">
        <win-personal></win-personal>

        <win-telefonos></win-telefonos>

        <win-informacion></win-informacion>

        <win-design></win-design>

        <win-redes></win-redes>
      </v-window>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          outlined
          rounded
          large
          :disabled="$store.state.step === 0"
          text
          @click="$store.state.step--"
        >
          Atras
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          :loading="system.loading"
          large
          rounded
          color="secondary"
          :disabled="$store.state.step === cuantStep"
          depressed
          @click="$store.state.step++"
        >
          Siguiente
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

//Currem Title
import { currentTitle, dataTitle } from "@/data/currentTitle";

import winPersonal from "@/components/windows/winPersonal";
import winTelefonos from "@/components/windows/winTelefonos";
import winInformacion from "@/components/windows/winInformacion";

import winDesign from "@/components/windows/winDesign";
import winRedes from "@/components/windows/winRedes";

export default {
  data: () => ({
    cuantStep: dataTitle.length - 1, //Obtener la cantidad de delizado
  }),
  watch: {
    contacto: {
      handler() {
        //Validar Dato
        this.validarContacto();

        //Guardar Contacto en el navegador (Borrador)
        localStorage.contacto = JSON.stringify(this.contacto);
      },
      deep: true,
    },
  },
  components: {
    winPersonal,
    winTelefonos,
    winInformacion,
    winDesign,
    winRedes,
  },
  methods: {
    ...mapActions(["generarContacto", "validarContacto"]),
    push() {
      this.generarContacto();
    },
  },
  computed: {
    ...mapState(["contacto", "step", "system"]),
    currentTitle,
  },
  created() {
    this.validarContacto();
  },
};
</script>
