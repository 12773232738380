export default [
  {
    name: "Instagram",
    icon: "mdi-instagram",
    placeholder: "Nombre de Usuario",
    img: "/img/redes/instagram.svg",
    type: "text",
    link: "https://instagram.com/",
    rule: (v) =>
      /^[a-zA-Z._0-9]{2,300}$/.test(v) ||
      !v ||
      "Instagram Incorrecto, elimine @ o cualquier símbolo, elimine espacios en blanco",
  },
  {
    name: "Whatsapp",
    icon: "mdi-whatsapp",
    placeholder: "Numero de Whatsapp",
    link: "https://wa.me/",
    type: "tel",
    img: "/img/redes/whatsapp.svg",
    rule: (v) =>
      /^(\d{1,4})\d{3}\d{3}\d{4}$/.test(v) ||
      !v ||
      "WhatsApp Incorrecto, agregue (1) o la extensión de su país, elimine símbolos como +, -,.",
  },
  {
    name: "Facebook",
    icon: "mdi-facebook",
    placeholder: "Nombre de Usuario",
    type: "text",
    link: "https://www.facebook.com/",
    img: "/img/redes/facebook.svg",
    rule: (v) =>
      /^[a-zA-Z._0-9]{2,300}$/.test(v) ||
      !v ||
      "Facebook Incorrecto, elimine @ o cualquier símbolo, elimine espacios en blanco",
  },
  {
    name: "Twitter",
    icon: "mdi-twitter",
    type: "text",
    placeholder: "Nombre de Usuario",
    link: "https://twitter.com/",
    img: "/img/redes/twitter.svg",
    rule: (v) =>
      /^[a-zA-Z._0-9]{2,300}$/.test(v) ||
      !v ||
      "Twitter Incorrecto, elimine @ o cualquier símbolo, elimine espacios en blanco",
  },
  {
    name: "LinkedIn",
    icon: "mdi-linkedin",
    placeholder: "Nombre de Usuario",
    type: "text",
    link: "http://ca.linkedin.com/in/",
    img: "/img/redes/linkedIn.svg",
    rule: (v) =>
      /^[a-zA-Z._0-9]{2,300}$/.test(v) ||
      !v ||
      "LinkedIn Incorrecto, elimine @ o cualquier símbolo, elimine espacios en blanco",
  },
  {
    name: "Telegram",
    type: "text",
    icon: "mdi-navigation-variant",
    placeholder: "Nombre de Usuario",
    link: "https://telegram.me/",
    img: "/img/redes/telegram.svg",
    rule: (v) =>
      /^[a-zA-Z._0-9]{5,300}$/.test(v) ||
      !v ||
      "Usuario de Telegram Incorrecto, elimine @ o cualquier símbolo, elimine espacios en blanco",
  },
];
